import * as React from "react";
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import How from "../components/How";
import Features from "../components/Features";
import Industries from "../components/Industries";
import Numbers from "../components/Numbers";
import CTA from "../components/CTA";
import dashboard1 from "../images/admin1.png";
import dashboard2 from "../images/admin2.png";
import dashboard3 from "../images/admin3.png";
import dashboard4 from "../images/admin4.png";
import PerksShort from "../components/PerksShort";
import "./index.sass";

// markup
const IndexPage = () => {
  return (
    <Layout>
      <Hero></Hero>
      <PerksShort></PerksShort>
      <How></How>
      <Features
        color="grape"
        content={features}
        direction="horizontal"
      ></Features>
      <Industries></Industries>
      <Numbers></Numbers>
      <CTA></CTA>
    </Layout>
  );
};

const features = [
  {
    subtext: "Tableau de bord",
    title: "Accédez à tous vos résultats en 2 clics.",
    text:
      "Grâce à votre interface privée Weely, vous pourrez accéder à des analyses poussées et une vision à 360° de vos résultats.",
  },
  {
    title: "Votre revenu généré",
    text:
      "Mesurez en temps réel le chiffre d’affaires généré par votre programme de fidélité. Identifiez les prix qui fonctionnent le mieux et améliorez constamment votre rentabilité.",
    image: dashboard1,
  },
  {
    title: "Vos chiffres réseaux sociaux",
    text:
      "Accédez en temps réel au nombre de nouveaux followers apportés par Weely sur vos réseaux sociaux (Instagram, Facebook, Google Business).",
    image: dashboard2,
  },
  {
    title: "Vos enquêtes clients",
    text:
      "Consultez les résultats de vos enquêtes clients pour affiner et mieux répondre à leurs attentes.",
    image: dashboard3,
  },
  {
    title: "Votre base de données client",
    text:
      "Consultez votre base de données client opt-in. Exportez-là au format Excel/CSV et communiquez par newsletter avec votre communauté.",
    image: dashboard4,
  },
];

export default IndexPage;
