import * as React from "react";
import "./How.sass";
import sticker from "../images/sticker_2.png";
import Perk from "./Perk";

const How = () => {
  return (
    <section id="how">
      <div className="how-holder">
        <img src={sticker} alt="weely sticker with qr"></img>
        <div>
          <Perk
            subtitle="Notre concept"
            title="Comment ça marche ?"
            text="En quatre étapes, Weely permet à vos clients de tenter leur chance
          pour gagner un prix à faire valoir lors de leur prochaine visite.
          C’est simple, vous n’avez rien à faire, tout se fait depuis leur
          smartphone !"
          ></Perk>
          <ul>
            <li>
              <span>1.</span> Scanner le QR
            </li>
            <li>
              <span>2.</span> Faire une action
            </li>
            <li>
              <span>3.</span> Tourner la roue
            </li>
            <li>
              <span>4.</span> Gagner un prix
            </li>
          </ul>
        </div>
      </div>

      <a
        className="button secondary"
        href="https://app.weely.ch/?id=5fbc3429cfd10f2f68c35258"
        target="_blank"
        rel="noreferrer"
      >
        Tester Weely
      </a>
    </section>
  );
};

export default How;
