import * as React from "react";
import "./Industries.sass";
import restaurant from "../images/industries/restaurant.png";
import coffee from "../images/industries/coffee.png";
import beauty from "../images/industries/star.png";
import boutique from "../images/industries/boutique.png";
import kiosk from "../images/industries/kiosk.png";
import store from "../images/industries/retail.png";
import event from "../images/industries/event.png";
import ecom from "../images/industries/ecommerce.png";

const Industries = () => {
  return (
    <section id="industries">
      <h3 id="title">Weely pour tout type de commerce</h3>
      <div id="industries-holder">
        <div className="industry-card">
          <div className="top-row">
            <h3>Restaurants</h3>
            <p>
              Foodtruck, restaurant traditionnel, fast-food, livraison,
              restauration à l'emporter
            </p>
          </div>
          <div className="bottom-row">
            <img src={restaurant} alt="restaurant icon"></img>
          </div>
        </div>
        <div className="industry-card">
          <div className="top-row">
            <h3>Cafés et Bars</h3>
            <p>
              Bar à cocktails, bar de nuit, bar de quartier, café spécialisé,
              bar éphémère, bar à chicha
            </p>
          </div>
          <div className="bottom-row">
            <img src={coffee} alt="coffee icon"></img>
          </div>
        </div>
        <div className="industry-card">
          <div className="top-row">
            <h3>Beauté</h3>
            <p>
              Salon de coiffure, institut de beauté, salon de massage, onglerie,
              barber shop, pharmacie
            </p>
          </div>
          <div className="bottom-row">
            <img src={beauty} alt="beauty icon"></img>
          </div>
        </div>
        <div className="industry-card">
          <div className="top-row">
            <h3>Boutiques</h3>
            <p>
              Epicerie fine, magasin de jouets, concept ephémère, magasin
              d’habits
            </p>
          </div>
          <div className="bottom-row">
            <img src={boutique} alt="boutique icon"></img>
          </div>
        </div>
      </div>
      <div id="industries-holder">
        <div className="industry-card">
          <div className="top-row">
            <h3>Kiosques</h3>
            <p>Journaux, tabac, alimentation</p>
          </div>
          <div className="bottom-row">
            <img src={kiosk} alt="kiosk icon"></img>
          </div>
        </div>
        <div className="industry-card">
          <div className="top-row">
            <h3>Retail</h3>
            <p>
              Grande distribution, magasin de bricolage, magasin de sport,
              magasin de décorations
            </p>
          </div>
          <div className="bottom-row">
            <img src={store} alt="store icon"></img>
          </div>
        </div>
        <div className="industry-card">
          <div className="top-row">
            <h3>Évènements</h3>
            <p>Salon, foire, dégustation, festival</p>
          </div>
          <div className="bottom-row">
            <img src={event} alt="event icon"></img>
          </div>
        </div>
        <div className="industry-card">
          <div className="top-row">
            <h3>E-commerce</h3>
            <p>Bientôt disponible..</p>
          </div>
          <div className="bottom-row">
            <img src={ecom} alt="ecommerce icon"></img>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Industries;
