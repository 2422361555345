import * as React from "react";
import "./Hero.sass";
import hero from "../images/hero2.png";
import { Link } from "gatsby";
import eldora from "../images/logos/el_dora.png";
import mangiobevo from "../images/logos/mangiobevo.png";
import paperbag from "../images/logos/paperbag.png";
import impression from "../images/logos/impression.png";
import bao from "../images/logos/bao.png";
import scrollTo from "gatsby-plugin-smoothscroll";

// markup
const Hero = () => {
  return (
    <section id="hero">
      <div id="left">
        <h1>Le jeu marketing pour votre commerce.</h1>
        <h4>
          Grâce à l’application Weely, répondez à tous vos besoins en marketing
          digital. Une solution simple, automatisée et accessible à tous.
        </h4>
        <div id="cta-holder">
          <Link className="button primary" to="/price">
            Essai gratuit
          </Link>
          <Link id="more" to="/contact">
            Contactez-nous {">"}
          </Link>
        </div>
        <div id="clients-holder">
          <img src={eldora} id="eldora" alt="eldora logo"></img>
          <img src={mangiobevo} id="mangiobevo" alt="mangiobevo logo"></img>
          <img src={paperbag} id="paperbag" alt="paperbag logo"></img>
          <img src={bao} id="bao" alt="bao logo"></img>
          <img src={impression} id="impression" alt="impression logo"></img>
        </div>
      </div>
      <img src={hero} alt="decorative weely design"></img>
    </section>
  );
};

export default Hero;
