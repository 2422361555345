import * as React from "react";
import "./PerksShort.sass";
import MiniPerk from "./MiniPerk";
import sales from "../images/icons/sales.png";
import social from "../images/icons/social.png";
import research from "../images/icons/research.png";
import newsletter from "../images/icons/newsletter.png";

const PerksShort = () => {
  return (
    <section id="perks-short">
      <div id="perk-title">
        <b>Nos avantages</b>
        <h2>La plateforme Weely c'est plus de...</h2>
      </div>
      <div id="perk-holder">
        <MiniPerk
          title="Ventes"
          text="Offrez une expérience unique à vos clients. Faites les revenir dans votre commerce et activez le bouche-à-oreille."
          image={sales}
        ></MiniPerk>
        <MiniPerk
          title="Satisfaction client"
          text="Améliorer votre connaissance client grâce à des enquêtes personnalisables. Vous réponderez encore mieux à leurs besoins."
          image={research}
        ></MiniPerk>
        <MiniPerk
          title="Followers & Avis"
          text="Boostez votre référencement sur les réseaux sociaux. Augmentez le nombre de followers ou apparaissez en haut des résultats Google."
          image={social}
        ></MiniPerk>
        <MiniPerk
          title="E-mails"
          text="Constituez votre base de données clients. Envoyez des newsletters pour agrandir votre communauté."
          image={newsletter}
        ></MiniPerk>
      </div>
    </section>
  );
};

export default PerksShort;
