import * as React from "react";
import "./MiniPerk.sass";

const MiniPerk = (props) => {
  return (
    <div className="mini-perk">
      <img src={props.image} alt="Perk icon"></img>
      <h3>{props.title}</h3>
      <p>{props.text}</p>
    </div>
  );
};

export default MiniPerk;
